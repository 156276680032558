import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import App from '../components/App';
import SEO from '../components/SEO';
import HeroInterior from '../components/HeroInterior';
import Grid from '../components/Grid';
import Container from '../components/Container';
import CardResource from '../components/CardResource';
import Wrapper from '../components/Wrapper';

export default function ResourcePage({ data }) {
  const { resource } = data;
  const { title, cases = [], downloads = [] } = resource;
  const subtitle = get(resource, 'description.description', '');

  const generateDir = (sys) => {
    if (!sys) return '';
    const { type } = sys;
    switch (type) {
      case 'ContentfulCaseStudy':
        return 'case-studies';
      default:
        return '';
    }
  };

  return (
    <App>
      <SEO {...resource} />
      <HeroInterior title={title} subtitle={subtitle} version="purple" />
      <Wrapper>
        <Container large>
          <h2>Case Studies</h2>
          <Grid>
            {cases.map(item => (
              <CardResource
                key={item.contentful_id}
                url={`/${generateDir(item.internal)}/${item.slug}`}
                {...item}
              />
            ))}
          </Grid>
          <h2 style={{ marginTop: '2rem' }}>Downloads</h2>
          <Grid>
            {downloads.map(download => (
              <CardResource
                small
                key={download.contentful_id}
                url={`/${generateDir(download.internal)}/${download.slug}`}
                {...download}
              />
            ))}
          </Grid>
        </Container>
      </Wrapper>
    </App>
  );
}

ResourcePage.propTypes = {
  data: PropTypes.shape({
    resources: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
};

export const query = graphql`
  {
    resource: contentfulResourceArchive(contentful_id: { eq: "1elTckgOesY2eDvyuZJnNo" }) {
      title
      description {
        description
      }
      cases {
        createdAt
        contentful_id
        title
        slug
        internal {
          type
        }
      }
      downloads {
        ... on ContentfulDownloadable {
          internal {
            type
          }
          link
          type
          contentful_id
          title
          file {
            title
            file {
              url
            }
          }
        }
        ... on ContentfulWebinar {
          internal {
            type
          }
          link
          contentful_id
          title
        }
      }
    }
  }
`;
