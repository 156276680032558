import styled from 'styled-components';
import theme from '../theme';

export default styled.div`
    background-color: ${({ variant }) => theme[variant]};
    padding: 6vh 3vw;

    @media (max-width: 890px){
      padding-top: 3vh;
      padding-bottom: 0;
    }
`;
